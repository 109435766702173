import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import request from "../utils/api";

export const useTags = () => {
  return useQuery({
    queryKey: ["tags"],
    queryFn: () => {
      return request({
        method: "GET",
        url: "/api/v1/tag/",
      });
    },
  });
};

export const useCreateTags = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body) => {
      return request({
        method: "POST",
        url: "/api/v1/tag/",
        data: [body],
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["tags"], (oldData) => {
        return {
          ...oldData,
          result: {
            data: [...oldData.result.data, data],
          },
        };
      });
      queryClient.invalidateQueries(["tags"]);
    },
  });
};

export const useEditTag = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body) => {
      return request({
        method: "PUT",
        url: "/api/v1/tag/" + id + "/",
        data: body,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tags"]);
    },
  });
};

export const useDeleteTag = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return request({
        method: "DELETE",
        url: "/api/v1/tag/" + id,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tags"]);
    },
  });
};
