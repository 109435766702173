import { useQuery } from "@tanstack/react-query";
import request from "../utils/api";

export const useDataPoints = (origin, metric, client, from_epoch, to_epoch) => {
  return useQuery({
    queryKey: ["data-points", origin, client, metric, from_epoch],
    queryFn: () => {
      return request({
        method: "GET",
        url: "/api/v1/dashboard/data/",
        params: {
          client_id: client,
          origin,
          metric,
          from_epoch,
          to_epoch,
        },
      });
    },
  });
};
