import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserAuthentication from './Authentication/userLogin';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import ProtectedRoute from './Authentication/privateRoute'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
      <Routes>
        <Route element={<ProtectedRoute/>}>
          <Route path='/*' exect element={ <App/> }/>
        </Route>
          <Route path='/Login' element={ <UserAuthentication/> } />
      </Routes>
    </Router>
);