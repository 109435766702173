import { Button, Popover } from "@mui/material";
import * as React from "react";
import { DateRangePicker } from "react-date-range";
import ClickAwayListener from "@mui/base/ClickAwayListener/";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css"; //

export default function DateRangePickerWrapper(props) {
  const { dateRange, setDateRange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSelect = (date) => {
    setDateRange({
      startDate: date.selection.startDate,
      endDate: date.selection.endDate,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const dateRangePickerRef = React.useRef();

  const handleClickAway = (event) => {
    if (
      dateRangePickerRef.current &&
      !dateRangePickerRef.current.contains(event.target)
    ) {
      setAnchorEl(null);
    }
  };

  const start = new Date(dateRange.startDate);
  const end = new Date(dateRange.endDate);

  const selectionRange = {
    startDate: start,
    endDate: end,
    key: "selection",
  };

  return (
    <>
      <Popover id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div ref={dateRangePickerRef}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              showSelectionPreview={false}
              months={2}
              direction="horizontal"
            />
          </div>
        </ClickAwayListener>
      </Popover>

      <Button variant="contained" aria-describedby={id} onClick={handleClick}>
        {format(start, "MMMM d, yyyy")} - {format(end, "MMMM d, yyyy")}
      </Button>
    </>
  );
}
