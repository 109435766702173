import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useMetrics } from "../hooks/useMetrics";
import DateRangePickerWrapper from "../Components/graph-item/date-range-picker";

import {
  useDashboardConfig,
  usePatchDashboardConfig,
} from "../hooks/useDashboard";
import WrapperDND from "./wrapperDND";
import { useQueryClient } from "@tanstack/react-query";

function PowerSourcesV2(props) {
  const { setNavIndex, origin, index, title, defaultMetric } = props;
  const queryClient = useQueryClient();
  const { data: items } = useDashboardConfig(origin);

  const { data: metrics } = useMetrics(origin);
  const mutation = usePatchDashboardConfig(origin);

  const [dateRange, setDateRange] = useState({
    startDate: new Date() - 7 * 24 * 60 * 60 * 1000,
    endDate: new Date(),
  });

  const handleNewGraph = () => {
    mutation.mutate([
      ...items,
      {
        graph_type: "line",
        metric: defaultMetric,
        order: items.length + 1,
        client: [
          {
            client_id: "source_panel_1_106",
            phase: ["phase_y", "phase_r"],
          },
        ],
      },
    ]);
  };

  const onChange = (items) => {
    queryClient.setQueryData(["dashboard-config", origin], () => items);
  };

  console.log("items", items);
  const handleSave = () => {
    mutation.mutate(items);
  };

  useEffect(() => {
    setNavIndex(index);
  }, [setNavIndex, index]);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          width: { md: `calc(100% - ${300}px)` },
          marginLeft: { sm: "300px" },
          justifyContent: "center",
          div: {
            columnGap: "10px",
          },
        }}
      >
        <Box display={"flex"} position={"fixed"} width={`calc(100% - 350px)`}>
          <Box flex={1}>
            <Typography variant="h4" fontWeight={600}>
              {title}
            </Typography>
          </Box>
          <Box
            flex={"0 !important"}
            style={{
              display: "flex",
              columnGap: "10px",
            }}
            minWidth={"max-content"}
          >
            <DateRangePickerWrapper
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Button variant="outlined" onClick={handleNewGraph}>
              Add New Graph
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>

        <WrapperDND
          items={items}
          metrics={metrics}
          dateRange={dateRange}
          onChange={onChange}
          origin={origin}
        />
      </Box>
    </React.Fragment>
  );
}

export default PowerSourcesV2;
