import axios from "axios";

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: "https://api.datasailors.io/iot-backend",
});

client.interceptors.request.use(async (request) => {
  const token = localStorage?.getItem("token");
  if (token) {
    request.headers.Authorization = `Token ${token}`;
  }
  return request;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const response = error.response;
    const statusCode = response?.status;
    if (statusCode === 401) {
      console.info("401 error, redirecting to login");
      localStorage.clear();
      window.location.href = "/login";
      return;
    }
    if (
      error?.response?.data?.errors?.length > 0 &&
      error.response.data.errors[0].message
    ) {
      // show Toast Message
    }
    throw error;
  }
);

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = function (response) {
    // console.debug("Request Successful!", response);
    return response?.data;
  };

  const onError = function (error) {
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.log(error, "error");

      // console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
