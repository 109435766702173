import { useQuery } from "@tanstack/react-query";
import request from "../utils/api";

export const useMetrics = (origin) => {
  return useQuery({
    queryKey: ["metrics", origin],
    queryFn: () => {
      return request({
        method: "GET",
        url: "/api/v1/dashboard/metric/",
        params: {
          origin: origin,
        },
      });
    },
  });
};
