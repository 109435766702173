import { useQuery } from "@tanstack/react-query";
import request from "../utils/api";

export const useClients = (client_type) => {
  return useQuery({
    queryKey: ["clients", client_type],
    queryFn: () => {
      return request({
        method: "GET",
        url: "/api/v1/allClients",
        data: {
          client_type,
        },
      });
    },
  });
};
