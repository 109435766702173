import { Box, Breadcrumbs, Card, CardMedia, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import { Area, AreaChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import React, { useEffect, useState } from "react";
import {overviewBackendAddress as backEnd, dummy24hourData as dummyData} from "./Constants"
import axios from "axios";
import { Done, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from "@mui/icons-material";
import { useNavigate} from "react-router-dom";


const COLORS = ['#8fbc8f', '#4682b4', '#cd5c5c'];
const powerColors=['#dcdcdc','#5f9ea0'];
const solarColors=['#dcdcdc', '#6a5acd'];

const liveActiveSourceData = [{sourceName:"Transformer 1 Max", P1_V : 80, P2_V: 65,P3_V : 75 , P1_W: 140 ,P2_W : 130, P3_W: 134},{sourceName:"Transformer 1", P1_V : 230, P2_V: 235,P3_V : 225, P1_W: 170,P2_W : 180, P3_W: 170} ];
const liveActiveSourceDataExtremes = [{value:1, name:"max"},{value:0, name:"min"}]
const solarHealthData =[{dataName:"Difference from Max", value:140},{dataName:"Current max", value:310}];

export default function Overview(props){
    const [sourcesData,setSourcesData] = useState(0);
    const [totalConsumption, setTotalConsumption] = useState(0);
    const [timeInterval, setTimeInterval] = useState("daily");
    const [sourcesEnergy, setSourcesEnergy] = useState(0);
    const [clientDBindex, setClientDBindex] = useState(0);
    const navigate = useNavigate();
    const handleIntervalChange = async (event, newTimeInterval) => {
        if(newTimeInterval!=null){
            setTimeInterval(newTimeInterval);
        }
    };
    const handleIntervalListChange = async (event) => {
            setTimeInterval(event.target.value);
    };

    useEffect( () => {
        props.setNavIndex(0);
        const getCardsData= async () =>{
            await axios({
                url:backEnd,
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: JSON.stringify({
                    timespan: timeInterval,
                  }),
                  
                }
            )
               .then((res)=>{
                setTotalConsumption(res.data.result.data.splice(3));
                setSourcesData(res.data.result.data);
                var normalizedSources=res.data.result.data;
                for(let i=0;i<res.data.result.data.length;i++){
                    if(res.data.result.data[i].energy_unit==="MWh"){
                        normalizedSources[i].energy=res.data.result.data[i].energy*1000;
                    }
                }
                setSourcesEnergy(normalizedSources);
            });
            }
        getCardsData();
    }, [timeInterval]);

    const onPieClick = (index, data) => {
        console.log(index.name);
        switch (index.name){
            case 0 :
                navigate("/Sources", {state : {clientDBindex:0}});
            break;
            case 1 : 
            navigate("/Sources", {state : {clientDBindex:2}});
            break;
            case 2 : 
            navigate("/Sources", {state : {clientDBindex:1}});
        }
    } 

    const voltMeterArtLines1 = [{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1},{value:1}];
    const voltMeterArtLines2 = [{value:1},{value:1},{value:1}];

    const liveChartVoltage=(chartKey) =>(
        <Box>
        <ResponsiveContainer width="100%" height={150}>
        <PieChart width={150} height={150}>
        <text y={130} x="50%" textAnchor="middle" dominantBaseline="middle" fontWeight={600} fill={props.mode==="dark" ? "#DCDCDC":"#808080"}>
        {liveActiveSourceData[1][chartKey]}V
        </text>
        <text y={"50%"} x={"35%"} textAnchor="middle" dominantBaseline="middle" fontWeight={600} fill={props.mode==="dark" ? "#DCDCDC":"#808080"} fontSize={"0.7rem"}>
        80
        </text>
        <text y={"50%"} x={"64%"} textAnchor="middle" dominantBaseline="middle" fontWeight={600} fill={props.mode==="dark" ? "#DCDCDC":"#808080"} fontSize={"0.7rem"}>
        240
        </text>
        <Pie data={liveActiveSourceDataExtremes} dataKey={"value"} innerRadius={60} outerRadius={74} labelline={false} startAngle={230} endAngle={-50} cornerRadius={7} fill="#DCDCDC" stroke={0}/>
        <Pie data={liveActiveSourceDataExtremes} dataKey={"value"} innerRadius={60} outerRadius={74} labelline={false} startAngle={230} endAngle={230-(280*(liveActiveSourceData[1].P2_V/300))} cornerRadius={8} fill="#3bbf9e"/>
        <Pie data={voltMeterArtLines1} dataKey={"value"} innerRadius={46} outerRadius={52} labelline={false} startAngle={231} endAngle={-51}  paddingAngle={9}/>
        <Pie data={voltMeterArtLines2} dataKey={"value"} innerRadius={42} outerRadius={52} labelline={false} startAngle={180} endAngle={0}  paddingAngle={85}/>
        </PieChart>
        </ResponsiveContainer>
        </Box>
);
    const liveChartPower=(chartKey) =>(
            <ResponsiveContainer width="100%" height={150}>
            <PieChart width={150} height={150}>
            <text y={75} x="50%" textAnchor="middle" dominantBaseline="middle" fontWeight={600} fill={props.mode==="dark" ? "white":"black"}>
            {liveActiveSourceData[1][chartKey]}kW
            </text>
            <Pie data={liveActiveSourceData} dataKey={chartKey} innerRadius={45} outerRadius={70} labelline={false} startAngle={90} endAngle={450}>
                    {liveActiveSourceData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={powerColors[index % powerColors.length]} style={{
                                    filter: `drop-shadow(0px 2px 4px gray)`
                                }}
                                stroke="0" /> ))
                            }
            </Pie>
            </PieChart>
            </ResponsiveContainer>
);


    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius +( (outerRadius - innerRadius) * 0.25);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {percent === 0 ? " " :`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const consumptionCards =(
        <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 6, sm: 8, md: 12 }}>
            <Grid item xs={6} sm={4} md={3}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", height:'75px',}} elevation={2}>
                        <Typography align="left" variant="h6" marginLeft={3} marginTop="2px" sx={{fontWeight:'550', color:'#696969'}}>Solar</Typography>
                        {sourcesData===0? <Box sx={{ maxWidth:"100%", height:"40px"}} justifyContent="center" display={"flex"} alignItems="center"><CircularProgress size="1.5rem"/></Box>:
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography align="left" variant="h5" marginLeft={3} sx={{fontWeight:'550'}}>{sourcesData && (sourcesData[0].energy_unit === "MWh" ? (sourcesData[0].energy/1000).toFixed(0): sourcesData[0].energy.toFixed(0))} {sourcesData && sourcesData[0].energy_unit}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="right" variant="h5" marginLeft={3} sx={{fontWeight:'550', color:(sourcesData && sourcesData[0].drift <= 0) ? 'error.light' : 'success.light', marginRight:'5px'}}>{sourcesData && (Math.abs(sourcesData[0].drift.toFixed(0))>100 ? 100:Math.abs(sourcesData[0].drift.toFixed(0)))}% {(sourcesData && sourcesData[0].drift >= 0) ?<KeyboardDoubleArrowUp /> :<KeyboardDoubleArrowDown />}</Typography>
                            </Grid>
                        </Grid>}
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", height:'75px'}} elevation={2}>
                        <Typography align="left" variant="h6" marginLeft={3} marginTop="2px" sx={{fontWeight:'550', color:'#696969'}}>Grid</Typography>
                        {sourcesData===0? <Box sx={{ maxWidth:"100%", height:"40px"}} justifyContent="center" display={"flex"} alignItems="center"><CircularProgress size="1.5rem"/></Box>:
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography align="left" variant="h5" marginLeft={3} sx={{fontWeight:'550'}}>{sourcesData && (sourcesData[1].energy_unit === "MWh" ? (sourcesData[1].energy/1000).toFixed(0): sourcesData[1].energy.toFixed(0))} {sourcesData && sourcesData[1].energy_unit}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="right" variant="h5" marginLeft={3} sx={{fontWeight:'550', color:(sourcesData && sourcesData[1].drift) >= 0 ? 'error.light' : 'success.light', marginRight:'5px'}}>{sourcesData && (Math.abs(sourcesData[1].drift.toFixed(0))>100 ? 100:Math.abs(sourcesData[1].drift.toFixed(0)))}% {(sourcesData && sourcesData[1].drift >= 0) ?<KeyboardDoubleArrowUp /> :<KeyboardDoubleArrowDown />}</Typography>
                            </Grid>
                        </Grid>}
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", height:'75px'}} elevation={2}>
                        <Typography align="left" variant="h6" marginLeft={3} marginTop="2px" sx={{fontWeight:'550', color:'#696969'}}>Diesel Generator</Typography>
                        {sourcesData===0? <Box sx={{ maxWidth:"100%", height:"40px"}} justifyContent="center" display={"flex"} alignItems="center"><CircularProgress size="1.5rem"/></Box>:
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography align="left" variant="h5" marginLeft={3} sx={{fontWeight:'550'}}>{sourcesData && (sourcesData[2].energy_unit === "MWh" ? (sourcesData[2].energy/1000).toFixed(0): sourcesData[2].energy.toFixed(0))} {sourcesData && sourcesData[2].energy_unit}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="right" variant="h5" marginLeft={3} sx={{fontWeight:'550', color: (sourcesData && sourcesData[2].drift) >= 0 ? 'error.light' : 'success.light', marginRight:'5px'}}>{sourcesData && (Math.abs(sourcesData[2].drift.toFixed(0))>100 ? 100:Math.abs(sourcesData[2].drift.toFixed(0)))}% {(sourcesData && sourcesData[2].drift >= 0) ?<KeyboardDoubleArrowUp /> :<KeyboardDoubleArrowDown />}</Typography>
                            </Grid>
                        </Grid>}
                    </Card>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", height:'75px'}} elevation={2}>
                        <Typography align="left" variant="h6" marginLeft={3} marginTop="2px" sx={{fontWeight:'550', color:'#696969'}}>Total Consumption</Typography>
                        {sourcesData===0? <Box sx={{ maxWidth:"100%", height:"40px"}} justifyContent="center" display={"flex"} alignItems="center"><CircularProgress size="1.5rem"/></Box>:
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography align="left" variant="h5" marginLeft={3} sx={{fontWeight:'550'}}>{totalConsumption &&(totalConsumption[0].energy.toFixed(0))} {totalConsumption && totalConsumption[0].energy_unit}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography align="right" variant="h5" marginLeft={3} sx={{fontWeight:'550', color:(totalConsumption && totalConsumption[0].drift) >= 0 ? 'error.light' : 'success.light', marginRight:'5px'}}>{totalConsumption && (Math.abs(totalConsumption[0].drift.toFixed(0))>100 ? 100:Math.abs(totalConsumption[0].drift.toFixed(0)))}% {(totalConsumption && totalConsumption[0].drift) >= 0 ? <KeyboardDoubleArrowUp /> :<KeyboardDoubleArrowDown />}</Typography>
                            </Grid>
                        </Grid>}
                    </Card>
                </Grid>
                </Grid>
    )
    const formatedInterval=()=>{
        if(timeInterval==="daily"){
            return "Daily"
        }
        else if(timeInterval==="monthly"){
            return "Monthly"
        }
        else return "Weekly"
    }   

    const sourcesChart = (     
        <ResponsiveContainer width="100%" height={400}>
                        <PieChart width={380} height={400}>
                        <Legend layout="horizontal"  align="center" marginBottom={5} payload={
                            sourcesData && sourcesData.map((item, index) => ({
                                type: "circle",
                                value: item.card_label==="Transformer" ? "Grid" : item.card_label,
                                color: COLORS[index % COLORS.length]
                            })
                            )
                        }/>
                        <Pie data={sourcesEnergy} dataKey="energy" innerRadius={100} outerRadius={150} paddingAngle={2} labelline={false} label={renderCustomizedLabel} cornerRadius={8} onClick={onPieClick}>
                        {sourcesData && sourcesData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{
                                filter: `drop-shadow(0px 2px 4px gray)`
                              }}
                              stroke="0" /> ))
                        }
                        </Pie>
                        </PieChart>
                </ResponsiveContainer>
    );

    return(
        <Box component="main"
        sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${340}px)` }, marginLeft:{sm: '300px'} }}>
            <Grid container columns={{ xs: 12}}>
                <Grid item xs={8}>
                <Breadcrumbs aria-label="breadcrumb" marginBottom={2} marginTop={1}>
                    <Typography color='primary'>Home</Typography>
                    <Typography color='primary'>Overview</Typography>
                </Breadcrumbs>
                </Grid>
                <Grid item xs={4} display={{xs:'none', md:"block"}}>
                <Box justifyContent="flex-end" display={{xs:'none', md:"flex"}}>
                <ToggleButtonGroup value={timeInterval} exclusive onChange={handleIntervalChange} aria-label="Interval" color="primary" size="small">
                    <ToggleButton value="daily">Daily</ToggleButton>
                    <ToggleButton value="weekly">Weekly</ToggleButton>
                    <ToggleButton value="monthly">Monthly</ToggleButton>
                </ToggleButtonGroup>
                </Box>
                </Grid>
                <Grid item xs={4} >
                <Box justifyContent="flex-end" display={{xs:'flex', md:"none"}}>
                <FormControl fullWidth>
                    <InputLabel>Interval</InputLabel>
                    <Select value={timeInterval} label="interval" onChange={handleIntervalListChange} size="small">
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                </FormControl>
                </Box>
                </Grid>
            </Grid>
            <Box marginBottom={2} sx={{display:{xs:'none', md:'block'}}}>
            {consumptionCards}
            </Box>
            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 6, md: 12 }}>                
                <Grid item xs={6} md={6}>
                <Card sx={{borderRadius:3, justifycontent : "center", maxWidth:"100%",pb:"10px"}} elevation={2}>
                    <Box display={{xs:'none', md:'block'}}><Typography align="center" marginTop={2} marginBottom={0} variant= "h5" sx={{fontWeight:'600', color:'#696969'}}> {formatedInterval()} Sources Consumption</Typography></Box>
                    <Box display={{xs:'block', md:'none'}}><Typography align="center" marginTop={2} marginBottom={0} variant= "h6" sx={{fontWeight:'600', color:'#696969'}}> {formatedInterval()} Sources Consumption</Typography></Box>
                    <CardMedia sx={{justifycontent:"center", Padding:"10vh"}}>
                        {sourcesData===0? <Box sx={{ maxWidth:"100%", height:"400px"}} justifyContent="center" display={"flex"} alignItems="center"><CircularProgress /></Box>:sourcesChart}
                    </CardMedia>
                </Card>
                </Grid>
                <Grid item xs={6} sm={8} md={6}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", padding:'20px'}} elevation={2}>
                            <Typography align="center" variant="h5" marginBottom={1.5} sx={{fontWeight:'600', color:'#696969'}}>Transformer 1</Typography>
                            <Grid container columns={{ xs: 8, md: 12 }}>
                                <Grid item xs={4}>
                                    <Box sx={{justifyContent:"center"}}>
                                    {liveChartVoltage("P1_V")}
                                    <Typography align="center" variant="h6" marginBottom={1} sx={{fontWeight:'500', color:'#696969'}}>Phase 1 Voltage</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{justifyContent:"center"}}>
                                    {liveChartVoltage("P2_V")}
                                    <Typography align="center" variant="h6" marginBottom={1} sx={{fontWeight:'500', color:'#696969'}}>Phase 2 Voltage</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{justifyContent:"center"}}>
                                    {liveChartVoltage("P3_V")}
                                    <Typography align="center" variant="h6" marginBottom={1} sx={{fontWeight:'500', color:'#696969'}}>Phase 3 Voltage</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{justifyContent:"center"}}>
                                    {liveChartPower("P1_W")}
                                    <Typography align="center" variant="h6" sx={{fontWeight:'500', color:'#696969'}}>Phase 1 Power</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{justifyContent:"center"}}>
                                    {liveChartPower("P2_W")}
                                    <Typography align="center" variant="h6" sx={{fontWeight:'500', color:'#696969'}}>Phase 2 Power</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{justifyContent:"center"}}>
                                    {liveChartPower("P3_W")}
                                    <Typography align="center" variant="h6" sx={{fontWeight:'500', color:'#696969'}}>Phase 3 Power</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                    </Card>
                </Grid>
            </Grid>
                <Box sx={{display:{xs:'block', md:'none'}, marginTop:"2vh"}}>
                    {consumptionCards}
                </Box>
            <Box marginTop={2}>
            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 6, sm: 8, md: 12 }}>
                <Grid item xs={6} sm={8} md={4}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", height:{xs:'390px', md:"400px"}, padding:"20px"}} elevation={2}>
                        <Typography align="center" variant="h5" marginBottom={1.5} sx={{fontWeight:'600', color:'#696969'}}>Solar Plant Status</Typography>
                        <ResponsiveContainer width="100%" height="75%">
                            <PieChart width={250} height={250}>
                            <text y="50%" x="50%" textAnchor="middle" dominantBaseline="middle" fontWeight={600} fontSize={25} fill={props.mode==="dark" ? "white":"black"}>
                                310kW
                            </text>
                            <Pie data={solarHealthData} dataKey={"value"} innerRadius={90} outerRadius={125} labelline={false} startAngle={90} endAngle={450}>
                                    {solarHealthData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={solarColors[index % solarColors.length]} style={{
                                                    filter: `drop-shadow(0px 2px 4px gray)`
                                                }}
                                                stroke="0" /> ))
                                            }
                            </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <Box>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                <Typography align="center" variant="h6" sx={{fontWeight:'500', color:'#696969'}}>Plant Health: <Chip icon={<Done />} label="Good" color="success" size="small"/></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <Typography align="center" variant="h6" sx={{fontWeight:'500', color:'#696969'}}>Plant Capacity: 450kW</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={8} md={8}>
                    <Card sx={{borderRadius:3, justifycontent:"center", maxWidth:"100%", height:{xs:'300px', md:"400px"} }} elevation={2}>
                    <Typography align="center" marginTop={2} marginBottom={0} variant= "h5" sx={{fontWeight:'600', color:'#696969'}}>Last 24 Hour Consumption</Typography>
                    <ResponsiveContainer width="100%" height="75%">
                        <AreaChart
                        width="90%"
                        height="80%"
                        data={dummyData}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                        >
                        <Legend layout="horizontal"  align="center" marginBottom={2} payload={
                            sourcesData && sourcesData.map((item, index) => ({
                                type: "circle",
                                value: item.card_label==="Transformer" ? "Grid" : item.card_label,
                                color: COLORS[index % COLORS.length]
                            })
                            )
                        }/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="solar" stackId="1" stroke="#8fbc8f" fill="#8fbc8f" />
                        <Area type="monotone" dataKey="grid" stackId="1" stroke="#4682b4" fill="#4682b4" />
                        <Area type="monotone" dataKey="dg" stackId="1" stroke="#cd5c5c" fill="#cd5c5c" />
                        </AreaChart>
                    </ResponsiveContainer>
                    </Card>
                </Grid>
            </Grid>
            </Box>
        </Box>
    )
}