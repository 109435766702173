import {
  Analytics,
  Dashboard,
  ElectricalServices,
  ElectricBolt,
  Help,
  Info,
  LegendToggle,
  MonetizationOn,
  Opacity,
  PropaneTank,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export default function DrawerContent(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(props.navIndex);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(props.navIndex);
  }, [props.navIndex]);

  return (
    <Box>
      <Toolbar
        sx={{
          "&, & .MuiListItemIcon-root": {
            color: "white",
            size: "large",
          },
        }}
      >
        <LegendToggle />
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            marginLeft: "1rem",
            color: "white",
            fontSize: "1.5rem",
          }}
        >
          Open Analytics
        </Typography>
      </Toolbar>
      <List
        disablePadding
        sx={{
          // selected and (selected + hover) states
          "&& .Mui-selected, && .Mui-selected:hover": {
            bgcolor: "#72A0C1",
            "&, & .MuiListItemIcon-root": {
              color: "white",
            },
          },
          // hover states
          "& .MuiListItemButton-root:hover": {
            bgcolor: "#F0F8FF",
            "&, & .MuiListItemIcon-root": {
              color: "black",
            },
          },
          color: "white",
          "&, & .MuiListItemIcon-root": {
            color: "white",
          },
        }}
      >
        <ListItem sx={{ py: "4px", color: "white" }} component={Link} to="/">
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => {
              handleListItemClick(event, 0);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ py: "4px", color: "white" }}
          component={Link}
          to="/Sources"
        >
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => {
              handleListItemClick(event, 1);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <ElectricBolt />
            </ListItemIcon>
            <ListItemText primary="Power Sources" />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ py: "4px", color: "white" }}
          component={Link}
          to="/Distribution"
        >
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => {
              handleListItemClick(event, 2);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <ElectricalServices />
            </ListItemIcon>
            <ListItemText primary="Power Distribution" />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={{ py: "4px", color: "white" }}
          component={Link}
          to="/water/generation"
        >
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <Opacity />
            </ListItemIcon>
            <ListItemText primary="Water Sources" />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ py: "4px", color: "white" }}
          component={Link}
          to="/water/distribution"
        >
          <ListItemButton
            selected={selectedIndex === 4}
            onClick={(event) => {
              handleListItemClick(event, 4);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <Opacity />
            </ListItemIcon>
            <ListItemText primary="Water Distribution" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ py: "4px", color: "white" }} component={Link} to="#">
          <ListItemButton
            //selected={selectedIndex === 4}
            //onClick={(event) => {handleListItemClick(event, 4);if(props.closeNav){props.closeNav()}}}
            sx={{ borderRadius: 3 }}
            disabled
          >
            <ListItemIcon>
              <PropaneTank />
            </ListItemIcon>
            <ListItemText primary="Gases" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ py: "4px", color: "white" }} component={Link} to="#">
          <ListItemButton
            //selected={selectedIndex === 5}
            //onClick={(event) => {handleListItemClick(event, 5);if(props.closeNav){props.closeNav()}}}
            sx={{ borderRadius: 3 }}
            disabled
          >
            <ListItemIcon>
              <MonetizationOn />
            </ListItemIcon>
            <ListItemText primary="Costing" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ py: "4px", color: "white" }} component={Link} to="#">
          <ListItemButton
            //selected={selectedIndex === 6}
            //onClick={(event) => {handleListItemClick(event, 6);if(props.closeNav){props.closeNav()}}}
            sx={{ borderRadius: 3 }}
            disabled
          >
            <ListItemIcon>
              <Analytics />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ py: "4px", color: "white" }}
          component={Link}
          to="/Help"
        >
          <ListItemButton
            selected={selectedIndex === 7}
            onClick={(event) => {
              handleListItemClick(event, 7);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ py: "4px", color: "white" }}
          component={Link}
          to="/About"
        >
          <ListItemButton
            selected={selectedIndex === 8}
            onClick={(event) => {
              handleListItemClick(event, 8);
              if (props.closeNav) {
                props.closeNav();
              }
            }}
            sx={{ borderRadius: 3 }}
          >
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
