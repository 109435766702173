import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import TagItem from "./tagItem";
import { useTags } from "../hooks/useTags";
import AddNewTag from "./tags/add-new-tag";

function Tags() {
  const { data, refetch } = useTags();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          width: { md: `calc(100% - ${350}px)` },
          marginLeft: { sm: "300px" },
          justifyContent: "center",
          div: {
            flex: 1,
            columnGap: "10px",
          },
        }}
      >
        <Box display={"flex"} position={"fixed"} width={`calc(100% - 400px)`}>
          <Box flex={1}>
            <Typography variant="h4" fontWeight={600}>
              Tags
            </Typography>
          </Box>
          <Box flex={"0 !important"} minWidth={"max-content"}>
            <Button variant="contained" onClick={() => setOpen(true)}>
              Add New Tag
            </Button>
          </Box>
        </Box>

        <Box
          style={{
            marginTop: "50px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          }}
        >
          {data?.map((tag) => (
            <TagItem key={tag.id} tag={tag} />
          ))}
        </Box>
      </Box>
      <AddNewTag open={open} setOpen={setOpen} refetch={refetch} />
    </React.Fragment>
  );
}

export default Tags;
