import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDeleteTag } from "../hooks/useTags";
import AddNewTag from "./tags/add-new-tag";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function TagItem(props) {
  const { tag } = props;
  const deleteTag = useDeleteTag(tag.id);
  const [editOpen, setEditOpen] = useState(false);

  const handleDelete = () => {
    deleteTag.mutate();
  };

  const handleEdit = () => {
    setEditOpen(true);
  };

  return (
    <Box
      key={tag.id}
      style={{
        padding: "10px",
        width: "100%",
        height: "200px",
        border: `1px solid ${tag.color}`,
        margin: "10px",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: `rgba(${hexToRgb(tag.color).r}, ${
          hexToRgb(tag.color).g
        }, ${hexToRgb(tag.color).b}, 0.05)`,
      }}
    >
      <AddNewTag open={editOpen} editMode setOpen={setEditOpen} tag={tag} />
      <Box
        style={{
          display: "flex",
          flexShrink: 0,
        }}
        flex={"0 !important"}
      >
        <Box style={{ flex: 1 }}>
          <Box
            style={{
              display: "flex",
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 18,
                flex: 1,
              }}
            >
              {tag.name}
            </Typography>
            <Box
              flex={"0 !important"}
              style={{
                display: "flex",
              }}
            >
              <Button size="small" variant="text" onClick={handleEdit}>
                Edit
              </Button>
              <Button
                size="small"
                color="error"
                variant="text"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Box>
          </Box>
          <Typography>{tag.description ? tag.description : "-"}</Typography>
        </Box>
      </Box>

      <Box
        style={{
          margin: "10px",
          borderRadius: "5px",
          overflow: "auto",
          flexGrow: 1,
        }}
      >
        <Typography
          style={{
            fontWeight: 600,
            fontSize: 16,
          }}
        >
          Clients
        </Typography>
        <Box>
          {tag.client_ids?.map((client, index) => {
            return (
              <Typography key={client}>
                {client.client_id}
                <div
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  {client.phase?.map((phase) => {
                    return <div>{phase}</div>;
                  })}
                </div>
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default TagItem;
