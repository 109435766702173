import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import request from "../utils/api";

export const useDashboardConfig = (origin) => {
  return useQuery({
    queryKey: ["dashboard-config", origin],
    queryFn: () => {
      return request({
        method: "GET",
        url: "/api/v1/dashboard/config",
        params: {
          origin,
        },
      });
    },
  });
};

export const usePatchDashboardConfig = (origin) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["dashboard-config"],
    mutationFn: async (body) => {
      const data = await request({
        method: "PUT",
        url: "/api/v1/dashboard/config/",
        params: {
          origin,
        },
        data: body,
      });
      queryClient.setQueryData(["dashboard-config", origin], data);
    },
  });
};
