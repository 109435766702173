import React, { useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";

import { useClients } from "../../hooks/useClients";
import { useCreateTags, useEditTag } from "../../hooks/useTags";
import ClientDropdown from "../../Components/graph-item/client-dropdown";

function AddNewTag(props) {
  const { open, setOpen, editMode, tag, refetch } = props;

  const { data: clients } = useClients();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [client, setClient] = useState([]);
  const [color, setColor] = useState("#fff");

  const mutation = useCreateTags();
  const editMutation = useEditTag(tag?.id);

  const handleSave = () => {
    const body = {
      name,
      description,
      color,
      client_ids: client,
    };
    if (editMode) {
      editMutation.mutate(body);
    } else mutation.mutate(body);
    setOpen(false);
    setName("");
    setDescription("");
    setClient([]);
    setColor("#fff");
    refetch();
  };

  useEffect(() => {
    if (editMode && tag) {
      setName(tag.name);
      setDescription(tag.description);
      setClient(tag.client_ids || []);
      setColor(tag.color);
    }
  }, [clients, editMode, tag]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {editMode ? "Edit tag" : "Add a new Tag"}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <TextField
            variant="outlined"
            placeholder="Tag Name"
            style={{
              width: "100%",
            }}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
            placeholder="Description"
            style={{
              width: "100%",
            }}
            multiline
            minRows={3}
            maxRows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <CirclePicker
            width="100%"
            color={color}
            onChangeComplete={(color) => setColor(color.hex)}
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <ClientDropdown
            title={"Client"}
            options={clients}
            value={client}
            setValue={setClient}
            multiple={true}
            fullWidth
          />
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, display: "flex", columnGap: "5px" }}
        >
          <Button variant="contained" onClick={handleSave}>
            {editMode ? "Edit" : "Save"}
          </Button>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}

export default AddNewTag;
