import React,{ useEffect, useState} from "react";
import { initializeApp } from "firebase/app";
import {getAuth,signInWithEmailAndPassword} from "firebase/auth";
import { Box, Button, Card, Dialog, DialogContent, Paper, TextField, Toolbar, Typography } from "@mui/material";
import {useNavigate } from "react-router-dom";
import Image from"./loginBackground.jpg";
import { borderRadius } from "@mui/system";

const firebaseConfig = {
  apiKey: "AIzaSyCPn4Z1hDnLgb-g6R3wAyDBAG7y0nmNY6M",
  authDomain: "funny-analytics.firebaseapp.com",
  projectId: "funny-analytics",
  storageBucket: "funny-analytics.appspot.com",
  messagingSenderId: "1069526677333",
  appId: "1:1069526677333:web:8c48cea98bb030f37ce1dc",
  measurementId: "G-YFCGPR6B8D"
};

const app = initializeApp(firebaseConfig);
const auth=getAuth();
var user=0;

const styles = {
    paperContainer: {
        backgroundImage: `url(${Image})`
        
    }
};


export default function UserAuthentication(props){
    const [userEmail, setUserEmail]= useState(0);
    const [userPass, setUserPass]= useState(0);
    const [errorCode,setErrorCode]= useState(" ");
    const navigate = useNavigate();

    useEffect(()=>{
        if(localStorage.getItem('openAnalyticsAccessToken')){navigate("/");}
    },[])


    const userLogIn= async (email, password)=>{
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            user = userCredential.user;
            localStorage.setItem('openAnalyticsAccessToken', user.uid);
            navigate("/");
        })
        .catch((error) => {
            setErrorCode(error.code);
    });
    }

    const handleChangeID = (event) => {
        setUserEmail(event.target.value);
    }

    const handleChangePass = (event) => {
        setUserPass(event.target.value);
    }
    const authFailMessage=()=>{
        var errorMessage=" ";
        if(errorCode==="auth/invalid-email"){
            errorMessage="invalid email address";

        } else if(errorCode==="auth/wrong-password"){
            errorMessage="incorrect password";
        }
        return errorMessage;
    }

    return(
        <React.Fragment>
        {!localStorage.getItem('openAnalyticsAccessToken') && (<Paper style={styles.paperContainer}>
            <Box minHeight="100vh">
                <Dialog open PaperProps={{
                        style: {
                        backgroundColor: 'transparent',
                        },
                        elevation:8,
                    }}>
                    <DialogContent>
                    <Box width="280px">
                    <Box width="100%" display="flex" justifyContent="center" marginTop="10%"><img src="./Images/openAnalytics.png" width="120px" alt=" "/></Box>
                    <Box width="100%" display="flex" justifyContent="center" marginTop="15%"><Typography variant="h4" sx={{fontWeight:"700", color:"#87CEFA"}}>Hello!</Typography></Box>
                    <Box width="100%" display="flex" justifyContent="center"><Typography variant="h6" sx={{fontWeight:"600", color:"#E6E6FA"}}>Please Sign in to continue</Typography></Box>
                    <Box width="100%" display="flex" justifyContent="center" marginTop="15%"><TextField id="loginID" label="Email ID" size="small" variant="filled" margin="normal" type="email" onChange={handleChangeID} fullWidth/></Box>
                    <Box width="100%" display="flex" justifyContent="center" marginTop="3%"><TextField id="userPassword" label="Password" size="small" variant="filled"margin="normal" type="password" onChange={handleChangePass} fullWidth/></Box>
                    <Box width="100%" display="flex" justifyContent="center" marginTop="5%"><Typography variant="p" sx={{fontWeight:"400", color:"red"}}>{authFailMessage()}</Typography></Box>
                    <Box width="100%" display="flex" justifyContent="center" marginTop="7%" marginBottom="10%"><Button variant="contained" onClick={()=>userLogIn(userEmail, userPass)} fullWidth margin="normal" size="large" sx={{bgcolor:"#000080", fontWeight:"700"}}>Login</Button></Box>
                    </Box></DialogContent>
                </Dialog>
                </Box>
            
        </Paper>)}
        </React.Fragment>
    )
};

