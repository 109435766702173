import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox } from "@mui/material";

export default function ClientDropdown(props) {
  const { options, value, setValue, title, multiple, fullWidth } = props;

  const handleChange = (client_id, phase_id) => {
    const isClientExist = value.find(
      (client) => client.client_id === client_id
    );
    if (isClientExist) {
      if (isClientExist.phase.includes(phase_id)) {
        isClientExist.phase = isClientExist.phase.filter(
          (phase) => phase !== phase_id
        );
        if (isClientExist.phase.length === 0) {
          setValue(value.filter((client) => client.client_id !== client_id));
          return;
        }
      } else {
        isClientExist.phase.push(phase_id);
      }
      setValue([...value]);
    } else {
      setValue([...value, { client_id, phase: [phase_id] }]);
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl size="small" fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
          value={value.map((client) => client.client_id).join(",")}
          label={title}
          onChange={() => {}}
          style={{ maxWidth: fullWidth ? "100%" : "200px" }}
          multiple={false}
          renderValue={(selected) => selected}
        >
          {options.map((option, index) => {
            return (
              <div key={`client-dropdown-${option.id}`}>
                <div>
                  <Checkbox
                    size="medium"
                    checked={
                      value.find(
                        (client) => client.client_id === option.client_id
                      )?.phase.length === option.phase.length
                    }
                    indeterminate={
                      value.find(
                        (client) => client.client_id === option.client_id
                      )?.phase.length < option.phase.length
                    }
                    onChange={() => {
                      const isClientExist = value.find(
                        (client) => client.client_id === option.client_id
                      );
                      if (isClientExist) {
                        setValue(
                          value.filter(
                            (client) => client.client_id !== option.client_id
                          )
                        );
                      } else {
                        setValue([
                          ...value,
                          {
                            client_id: option.client_id,
                            phase: option.phase.map((phase) => phase.id),
                          },
                        ]);
                      }
                    }}
                  />
                  {option.client_label}
                </div>
                <div style={{ marginLeft: "15px", fontSize: "15px" }}>
                  {option.phase?.map((phase) => {
                    return (
                      <div>
                        <Checkbox
                          size="small"
                          checked={
                            value
                              .find(
                                (client) =>
                                  client.client_id === option.client_id
                              )
                              ?.phase.includes(phase.id) || false
                          }
                          onChange={() => {
                            handleChange(option.client_id, phase.id);
                          }}
                        />
                        {phase.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
