const overviewBackendAddress="https://api.datasailors.io/iot-backend/v1/overviewConsumptionCards";
const energyAddress="https://api.datasailors.io/iot-backend/v1/energyHistogram";
const powerAddress="https://api.datasailors.io/iot-backend/v1/powerHistogram";
const waterVolume="https://api.datasailors.io/iot-backend/v1/waterVolumeHistogram";
const clientsDB="https://api.datasailors.io/iot-backend/v1/allClients";
const waterFlow="https://api.datasailors.io/iot-backend/v1/waterFlowHistogram";
export { overviewBackendAddress, energyAddress, clientsDB, powerAddress, waterVolume, waterFlow };

const dummy24hourData= [
    {
      name: '8:00PM',
      solar: 40,
      grid: 370,
      dg: 20,
    },
    {
      name: '9:00PM',
      solar: 36,
      grid: 298,
      dg: 0,
    },
    {
      name: '10:00PM',
      solar: 30,
      grid: 290,
      dg: 0,
    },
    {
      name: '11:00PM',
      solar: 27,
      grid: 360,
      dg: 0,
    },
    {
      name: '12:00AM',
      solar: 18,
      grid: 290,
      dg: 0,
    },
    {
      name: '1:00AM',
      solar: 23,
      grid: 280,
      dg: 0,
    },
    {
      name: '2:00AM',
      solar: 32,
      grid: 330,
      dg: 20,
    },
    {
        name: '3:00AM',
        solar: 25,
        grid: 240,
        dg: 80,
      },
      {
        name: '4:00AM',
        solar: 30,
        grid: 60,
        dg: 220,
      },
      {
        name: '5:00AM',
        solar: 20,
        grid: 280,
        dg: 0,
      },
      {
        name: '6:00AM',
        solar: 27,
        grid: 390,
        dg: 0,
      },
      {
        name: '7:00AM',
        solar: 18,
        grid: 350,
        dg: 0,
      },
      {
        name: '8:00AM',
        solar: 23,
        grid: 380,
        dg: 20,
      },
  ];
  export {dummy24hourData};
