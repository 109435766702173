import React, { Component, createContext } from "react";
import "./App.css";
import NavBar from "./Components/nav-bar";
import Overview from "./Pages/Overview";
import Sources from "./Pages/powerSources";
import Water from "./Pages/water";
import Gases from "./Pages/gases";
import Costing from "./Pages/costing";
import Analytics from "./Pages/analytics";
import Help from "./Pages/help";
import About from "./Pages/about";
import Tags from "./Pages/tags";
import Distribution from "./Pages/powerDistribution";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SourcesV2 from "./Pages/powerSourcesV2";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const ColorModeContext = createContext({ toggleColorMode: () => {} });

// Create a client
const queryClient = new QueryClient();

class appMain extends Component {
  constructor(props) {
    super(props);
    this.State = {
      mode: "light",
      userID: props.userID,
      sideNavIndex: 0,
    };
    this.toggleColorMode = this.toggleColorMode.bind(this);
    this.setNavIndex = this.setNavIndex.bind(this);
  }

  toggleColorMode() {
    const newMode = this.State.mode === "light" ? "dark" : "light";
    this.State.mode = newMode;
    this.setState({ mode: newMode });
  }
  setNavIndex(newIndex) {
    this.State.sideNavIndex = newIndex;
    this.setState({ sideNavIndex: newIndex });
  }

  render() {
    const colorMode = {
      toggleColorMode: () => {
        this.State.mode = this.State.mode === "light" ? "dark" : "light";
      },
    };

    const theme = createTheme({
      palette: {
        mode: this.State.mode,
        ...(this.State.mode === "light" && {
          background: {
            default: "#f0f2f5",
            paper: "#f0f2f5",
          },
        }),
        ...(this.State.mode === "dark" && {
          background: {
            default: "#0b141a",
            paper: "#0b141a",
          },
        }),
      },
      components: {
        MuiToggleButton: {
          styleOverrides: {
            root: {
              minWidth: "80px",
              height: "30px",
            },
          },
        },
      },
    });

    return (
      <React.Fragment>
        <QueryClientProvider client={queryClient}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <NavBar
                changeTheme={this.toggleColorMode}
                mode={this.State.mode}
                navIndex={this.State.sideNavIndex}
              />
              <Routes>
                <Route
                  path="/"
                  exect
                  element={
                    <Overview
                      mode={this.State.mode}
                      setNavIndex={this.setNavIndex}
                    />
                  }
                />
                <Route
                  path="/Sources"
                  element={
                    <SourcesV2
                      index={1}
                      mode={this.State.mode}
                      setNavIndex={this.setNavIndex}
                      origin={"electrical_generation"}
                      title={"Power Sources"}
                      defaultMetric={"voltage_rms"}
                    />
                  }
                />
                <Route
                  path="/Distribution"
                  element={
                    <SourcesV2
                      index={2}
                      mode={this.State.mode}
                      setNavIndex={this.setNavIndex}
                      origin={"electrical_distribution"}
                      title={"Power Distribution"}
                      defaultMetric={"voltage_rms"}
                    />
                  }
                />
                <Route
                  path="/water/generation"
                  element={
                    <SourcesV2
                      index={3}
                      mode={this.State.mode}
                      setNavIndex={this.setNavIndex}
                      origin={"water_generation"}
                      title={"Water Sources"}
                      defaultMetric={"flow_rate"}
                    />
                  }
                />
                <Route
                  path="/water/distribution"
                  element={
                    <SourcesV2
                      index={4}
                      mode={this.State.mode}
                      setNavIndex={this.setNavIndex}
                      origin={"water_distribution"}
                      title={"Water Distribution"}
                      defaultMetric={"flow_rate"}
                    />
                  }
                />
                <Route
                  path="/Gases"
                  element={<Gases setNavIndex={this.setNavIndex} />}
                />
                <Route
                  path="/Costing"
                  element={<Costing setNavIndex={this.setNavIndex} />}
                />
                <Route
                  path="/Analytics"
                  element={<Analytics setNavIndex={this.setNavIndex} />}
                />
                <Route
                  path="/Help"
                  element={<Help setNavIndex={this.setNavIndex} />}
                />
                <Route
                  path="/About"
                  element={<About setNavIndex={this.setNavIndex} />}
                />
                <Route
                  path="/tags"
                  element={<Tags setNavIndex={this.setNavIndex} />}
                />
              </Routes>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </QueryClientProvider>
      </React.Fragment>
    );
  }
}

export default appMain;
