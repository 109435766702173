import { Box } from "@mui/system";

export default function Gases(){
    return(
    <div>
        <Box sx={{flexGrow: 1, p: 2, width: { sm: `calc(100% - ${340}px)` }, marginLeft:{sm: '300px'}, justifyContent:"center"}}>
        <h2> Gases Page is under Development</h2>
        </Box>
    </div>
    )
}