import { Chart as ChartJS, registerables } from "chart.js";
import React, { useState } from "react";
import {
  Doughnut,
  PolarArea,
  Bar,
  Pie,
  Line,
  Bubble,
  Radar,
} from "react-chartjs-2";
import Dropdown from "./dropdown";
import { useClients } from "../../hooks/useClients";
import { useDataPoints } from "../../hooks/useDataPoints";
import { useTags } from "../../hooks/useTags";
import ClientDropdown from "./client-dropdown";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@mui/material";

ChartJS.register(...registerables);

const GRAPH_TYPES = [
  { id: "line", label: "Line" },
  { id: "area", label: "Area" },
  { id: "bar", label: "Bar" },
  { id: "pie", label: "Pie" },
  { id: "doughnut", label: "Doughnut" },
];

const colorSet = ["#7CB9E8", "#89CFF0", "#00FFFF", "#7FFFD4"];

const getGraph = (graph_type, data) => {
  let dataValues = [];
  Object.keys(data.values).forEach((key, index) => {
    dataValues.push({
      label: key,
      data: data.values[key],
      fill: false,
      backgroundColor: colorSet[index],
      lineTension: 0.5,
    });
  });

  const formattedData = {
    labels: data.timestamps.map((timestamp) =>
      new Date(timestamp * 1000).toLocaleString()
    ),
    datasets: dataValues,
  };

  const options = {
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 7,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const hiddenLegend = {
    ...options,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  switch (graph_type) {
    case "area":
      return (
        <PolarArea
          data={formattedData}
          options={{
            ...options,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      );
    case "bar":
      return <Bar data={formattedData} options={options} />;
    case "pie":
      return <Pie data={formattedData} options={hiddenLegend} />;
    case "line":
      return <Line data={formattedData} options={options} />;
    case "bubble":
      return <Bubble data={formattedData} options={options} />;
    case "doughnut":
      return <Doughnut data={formattedData} options={hiddenLegend} />;
    case "radar":
      return <Radar data={formattedData} options={options} />;
    default:
      return <Doughnut data={formattedData} options={hiddenLegend} />;
  }
};

function GridItem(props) {
  const { item, metrics, dateRange, origin } = props;
  const {
    graph_type,
    metric: originalMetric,
    client: originalClient,
    tag: originalTag,
  } = item;

  const { data: clients } = useClients(origin);
  const { data: tags } = useTags();
  const queryClient = useQueryClient();

  const [graphType, setGraphType] = useState(graph_type);
  const [metric, setMetric] = useState(originalMetric);
  const [client, setClient] = useState(originalClient ?? []);
  const [tag, setTag] = useState(originalTag ?? -1);

  const getEpoch = (date) => {
    return Math.floor(new Date(date).getTime() / 1000);
  };
  const clientString = (client) => {
    let res = "";
    client.forEach((c) => {
      c.phase.forEach((p) => {
        res = res + c.client_id + "_" + p + ",";
      });
    });
    res = res.slice(0, -1);
    return res;
  };

  const { data } = useDataPoints(
    origin,
    metric,
    clientString(client),
    getEpoch(dateRange.startDate),
    getEpoch(dateRange.endDate)
  );

  return (
    <div
      style={{
        padding: "10px",
        width: "750",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: 10,
          margin: 10,
          height: 700,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
          flexDirection: "column",
        }}
      >
        {data && getGraph(graphType, data)}
      </div>
      <div
        style={{
          width: "100%",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Dropdown
          title={"Graph Type"}
          options={GRAPH_TYPES}
          value={graphType}
          setValue={(val) => {
            queryClient.setQueryData(["dashboard-config", origin], (prev) => {
              return prev.map((item) => {
                if (item.id === props.item.id) {
                  return {
                    ...item,
                    graph_type: val,
                  };
                }
                return item;
              });
            });
            setGraphType(val);
          }}
        />
        {metrics && (
          <Dropdown
            title={"Metric"}
            options={
              metrics.map((metric) => {
                return { id: metric, label: metric };
              }) ?? []
            }
            value={metric}
            setValue={(val) => {
              queryClient.setQueryData(["dashboard-config", origin], (prev) => {
                return prev.map((item) => {
                  if (item.id === props.item.id) {
                    return {
                      ...item,
                      metric: val,
                    };
                  }
                  return item;
                });
              });
              setMetric(val);
            }}
          />
        )}
        {clients && (
          <ClientDropdown
            title={"Client"}
            options={clients}
            value={client}
            setValue={(clients) => {
              queryClient.setQueryData(["dashboard-config", origin], (prev) => {
                return prev.map((item) => {
                  if (item.id === props.item.id) {
                    return {
                      ...item,
                      client: clients,
                    };
                  }
                  return item;
                });
              });

              setClient(clients);
            }}
            multiple={true}
            fullWidth
          />
        )}
        {tags && (
          <Dropdown
            title={"Tags"}
            options={tags.map((tag) => {
              return { id: tag.id, label: tag.name };
            })}
            value={tag}
            setValue={(val) => {
              setTag(val);

              const selectedTag = tags.find((t) => t.id === val);
              if (selectedTag) {
                setClient(selectedTag.client_ids);
              }

              queryClient.setQueryData(["dashboard-config", origin], (prev) => {
                return prev.map((item) => {
                  if (item.id === props.item.id) {
                    return {
                      ...item,
                      tag: val,
                      client: selectedTag.client_ids,
                    };
                  }
                  return item;
                });
              });
            }}
          />
        )}
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            queryClient.setQueryData(["dashboard-config", origin], (prev) => {
              return prev.filter((item) => item.id !== props.item.id);
            });
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}

export default GridItem;
